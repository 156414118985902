import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`I'm making this for my own reference, but I hope it helps you out as well!`}</p>
    <h2 {...{
      "id": "language-features"
    }}><a parentName="h2" {...{
        "href": "#language-features"
      }}>{`Language Features`}</a></h2>
    <h4 {...{
      "id": "variables"
    }}><a parentName="h4" {...{
        "href": "#variables"
      }}>{`Variables`}</a></h4>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// Constant variable`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` foo `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Stable constant`}</span>{`
stable `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` foo `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Mutable variable`}</span>{`
var foo `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Stable mutable`}</span>{`
stable var foo `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h4 {...{
      "id": "conditionals"
    }}><a parentName="h4" {...{
        "href": "#conditionals"
      }}>{`Conditionals`}</a></h4>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` foo `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token boolean"
        }}>{`true`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`else`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`2`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`mut`}</span>{` foo2`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`0`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`if`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token boolean"
        }}>{`true`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  foo2 `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`else`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  foo2 `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`2`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// Do block - not always practical but worth knowing`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` option `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`3`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` foo3 `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`do`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` unpackedOption `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token macro property"
        }}>{`option!`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{` `}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// the \`!\` operator "unpacks" the option and gets you the value inside. If the option is null, the entire do ? {} block evaluates to null`}</span>{`
    `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`toText unpackedOption
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}</code></pre>
    <h4 {...{
      "id": "assignment"
    }}><a parentName="h4" {...{
        "href": "#assignment"
      }}>{`Assignment`}</a></h4>
    <pre {...{
      "className": "language-swift"
    }}><code parentName="pre" {...{
        "className": "language-swift"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`var`}</span>{` foo `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`""`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
foo `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"new text"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h4 {...{
      "id": "loops"
    }}><a parentName="h4" {...{
        "href": "#loops"
      }}>{`Loops`}</a></h4>
    <pre {...{
      "className": "language-swift"
    }}><code parentName="pre" {...{
        "className": "language-swift"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` items `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"fizz"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"buzz"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token string-literal"
        }}><span parentName="span" {...{
            "className": "token string"
          }}>{`"fizzBuzz"`}</span></span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`for`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`val `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`in`}</span>{` items`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`vals`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Debug`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`print`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`debug_show val`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <h4 {...{
      "id": "variants"
    }}><a parentName="h4" {...{
        "href": "#variants"
      }}>{`Variants`}</a></h4>
    <pre {...{
      "className": "language-swift"
    }}><code parentName="pre" {...{
        "className": "language-swift"
      }}>{`type `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Foo`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token other-directive property"
        }}>{`#bar`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token other-directive property"
        }}>{`#baz`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

type `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Complex`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token other-directive property"
        }}>{`#foo`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Foo`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token other-directive property"
        }}>{`#bar`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token other-directive property"
        }}>{`#baz`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h4 {...{
      "id": "imports"
    }}><a parentName="h4" {...{
        "href": "#imports"
      }}>{`Imports`}</a></h4>
    <pre {...{
      "className": "language-dart"
    }}><code parentName="pre" {...{
        "className": "language-dart"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// base library`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:base/Nat"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// canister import`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`import`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Whoami`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"canister:whoami"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token comment"
        }}>{`// package import`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`import`}</span>{` IC `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:management-canister"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h4 {...{
      "id": "management-canister"
    }}><a parentName="h4" {...{
        "href": "#management-canister"
      }}>{`Management Canister`}</a></h4>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token constant"
        }}>{`IC`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` actor `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    http_request `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Types`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`CanisterHttpRequestArgs`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`->`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Types`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`CanisterHttpResponsePayload`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` ic `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token constant"
        }}>{`IC`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`actor`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"aaaaa-aa"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`import `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Principal`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:base/Principal"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
import `}<span parentName="code" {...{
          "className": "token constant"
        }}>{`IC`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:management-canister"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`


actor `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Example`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`

  `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`type`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`CanisterStatus`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    cycles `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    idle_cycles_burned_per_day `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    memory_size `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    module_hash `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat8`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    settings `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`

    freezing_threshold `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    controllers `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Principal`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    memory_allocation `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    compute_allocation `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    status `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`#running`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{` #stopped`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{` #stopping`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

  public func `}<span parentName="code" {...{
          "className": "token function"
        }}>{`status`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`async`}</span>{`  `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`CanisterStatus`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` management`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token constant"
        }}>{`IC`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token keyword"
        }}>{`Self`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token function"
        }}>{`actor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"aaaaa-aa"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`await`}</span>{` management`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`canister_status`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`canister_id `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Principal`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`fromActor`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Example`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <p>{`For Vessel dependencies, see `}<a parentName="p" {...{
        "href": "#vessel"
      }}>{`Vessel`}</a></p>
    <h2 {...{
      "id": "data-structures"
    }}><a parentName="h2" {...{
        "href": "#data-structures"
      }}>{`Data Structures`}</a></h2>
    <h3 {...{
      "id": "array"
    }}><a parentName="h3" {...{
        "href": "#array"
      }}>{`Array`}</a></h3>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// init`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` arr `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`2`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`3`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h3 {...{
      "id": "iter"
    }}><a parentName="h3" {...{
        "href": "#iter"
      }}>{`Iter`}</a></h3>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`import `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Iter`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:base/Iter"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` iter `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Iter`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`make`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` fromArr `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Iter`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`fromArray`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`arr`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` arr2 `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Iter`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`toArray`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`fromArr`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <h3 {...{
      "id": "buffer"
    }}><a parentName="h3" {...{
        "href": "#buffer"
      }}>{`Buffer`}</a></h3>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`import `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Buffer`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"mo:base/Buffer"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` arr `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`1`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`2`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span><span parentName="code" {...{
          "className": "token number"
        }}>{`3`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` buf `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Buffer`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Buffer`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`<`}</span><span parentName="code" {...{
          "className": "token class-name"
        }}>{`T`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`>`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`size`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`for`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`x `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`in`}</span>{` arr`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`vals`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    buf`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`add`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`x`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` newArr `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` buf`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`toArray`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}</code></pre>
    <h3 {...{
      "id": "trie"
    }}><a parentName="h3" {...{
        "href": "#trie"
      }}>{`Trie`}</a></h3>
    <h3 {...{
      "id": "triemap"
    }}><a parentName="h3" {...{
        "href": "#triemap"
      }}>{`TrieMap`}</a></h3>
    <h3 {...{
      "id": "hashmap"
    }}><a parentName="h3" {...{
        "href": "#hashmap"
      }}>{`HashMap`}</a></h3>
    <h2 {...{
      "id": "vessel"
    }}><a parentName="h2" {...{
        "href": "#vessel"
      }}>{`Vessel`}</a></h2>
    <pre {...{
      "className": "language-json"
    }}><code parentName="pre" {...{
        "className": "language-json"
      }}><span parentName="code" {...{
          "className": "token comment"
        }}>{`// dfx.json`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
  `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"defaults"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"build"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"args"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`""`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{`
      `}<span parentName="code" {...{
          "className": "token property"
        }}>{`"packtool"`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"vessel sources"`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
  `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span>{`
`}</code></pre>
    <pre {...{
      "className": "language-txt"
    }}><code parentName="pre" {...{
        "className": "language-txt"
      }}>{`// package-set.dhall
let upstream =
  https://github.com/dfinity/vessel-package-set/releases/download/mo-0.6.21-20220215/package-set.dhall sha256:b46f30e811fe5085741be01e126629c2a55d4c3d6ebf49408fb3b4a98e37589b

let packages = [
  { name = "management-canister"
  , repo = "https://github.com/krpeacock/ic-management-canister"
  , version = "main"
  , dependencies = [ "base" ]
  },
]

in  upstream # packages
`}</code></pre>
    <pre {...{
      "className": "language-txt"
    }}><code parentName="pre" {...{
        "className": "language-txt"
      }}>{`// vessell.dhall
{ dependencies = [ "base", "management-canister" ], compiler = Some "0.6.21" }

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      